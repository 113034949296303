// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-tsx": () => import("../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-documentation-tsx": () => import("../src/pages/documentation.tsx" /* webpackChunkName: "component---src-pages-documentation-tsx" */),
  "component---src-pages-fake-friends-tsx": () => import("../src/pages/fake-friends.tsx" /* webpackChunkName: "component---src-pages-fake-friends-tsx" */),
  "component---src-pages-faq-tsx": () => import("../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-subscription-annual-tsx": () => import("../src/pages/subscription/annual.tsx" /* webpackChunkName: "component---src-pages-subscription-annual-tsx" */),
  "component---src-pages-subscription-friends-and-family-tsx": () => import("../src/pages/subscription/friends-and-family.tsx" /* webpackChunkName: "component---src-pages-subscription-friends-and-family-tsx" */),
  "component---src-pages-subscription-index-tsx": () => import("../src/pages/subscription/index.tsx" /* webpackChunkName: "component---src-pages-subscription-index-tsx" */),
  "component---src-pages-subscription-success-tsx": () => import("../src/pages/subscription/success.tsx" /* webpackChunkName: "component---src-pages-subscription-success-tsx" */),
  "component---src-pages-subscription-yc-tsx": () => import("../src/pages/subscription/yc.tsx" /* webpackChunkName: "component---src-pages-subscription-yc-tsx" */),
  "component---src-pages-terms-tsx": () => import("../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-web-index-tsx": () => import("../src/pages/web/index.tsx" /* webpackChunkName: "component---src-pages-web-index-tsx" */)
}

